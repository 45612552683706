import { AcceptanceStatuses, AcceptanceUIStatuses  } from '~/layers/settlement/constants/statuses';

export const useAcceptanceStatus = () => {
    /**  
    * на основе статуса определяем цвет chip (текст приходит в ответе на запрос)    
    * @param status статус приемки 
    */
    function getAcceptanceStatusColor(status: string): string {
        switch (status) {
        case AcceptanceStatuses.Draft:
        case AcceptanceStatuses.Appointmented:
        case AcceptanceStatuses.Participant:
        case AcceptanceStatuses.CheckPersonalData:
        case AcceptanceStatuses.ChooseDate:
            return "blue";
        case AcceptanceStatuses.Meeting:
        case AcceptanceStatuses.NeedPrepareDocuments:
            return "yellow";
        case AcceptanceStatuses.SuccessResult:
            return "green";
        case AcceptanceStatuses.ResultWithRemarks:
        case AcceptanceStatuses.FailResult:
            return "orange";
        case AcceptanceStatuses.Cancelled:
            return "grey";
        default:
            return "";
        }
    }

    /** 
    * Определяет цвет селекта по UI статусам
    * @param status статус приемки
    */
    function getAcceptanceUIStatusColor(status: string): string {
        switch (status) {
        case AcceptanceUIStatuses.New:
        case AcceptanceUIStatuses.Invited:
            return "blue";
        case AcceptanceUIStatuses.Appointmented:
        case AcceptanceUIStatuses.Prepared:
            return "yellow";
        case AcceptanceUIStatuses.SuccessResult:
            return "green";
        case AcceptanceUIStatuses.ResultWithRemarks:
        case AcceptanceUIStatuses.FailResult:
            return "orange";
        case AcceptanceUIStatuses.Cancelled:
            return "grey";
        default:
            return "";
        }
    }
    
    /** 
    * Определяет цвет селекта по статусам для собственника
    * @param status статус приемки
    */
    function getAcceptanceClientStatusColor(status: string): string {
        switch (status) {
        case AcceptanceStatuses.Draft:
        case AcceptanceStatuses.Appointmented:
        case AcceptanceStatuses.Participant:
        case AcceptanceStatuses.CheckPersonalData:
        case AcceptanceStatuses.ChooseDate:
        case AcceptanceStatuses.NeedPrepareDocuments:
        case AcceptanceStatuses.Meeting:
            return "blue";
        case AcceptanceStatuses.SuccessResult:
            return "green";
        case AcceptanceStatuses.ResultWithRemarks:
        case AcceptanceStatuses.FailResult:
            return "orange";
        case AcceptanceUIStatuses.Cancelled:
            return "grey";
        default:
            return "";
        }
    }  

    return {
        getAcceptanceStatusColor,
        getAcceptanceUIStatusColor,
        getAcceptanceClientStatusColor,
    };
};